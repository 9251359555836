import { Box, Container, Paper, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";

function Result() {
  const screenSwitches = useSelector((state) => state.proctorReducer.switches);
  const proctorcount = useSelector((state) => state.proctorcountReducer.proctorcount);
  const truecount = useSelector((state) => state.proctorcountReducer. trueCount);
  const percentage = (truecount /proctorcount) * 100;
  

  return (
    <Container
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        mt: "20%",
      }}
    >
      <Paper elevation={5} sx={{ p: 4, textAlign: "center" }}>
        <Typography fontWeight={800} fontSize={20}>Your Test Has Been Submitted!! ✔️</Typography>
        <Typography>Your Score </Typography>
        <Typography>
          Your result and invigilation report have been sent to admin 🙂
        </Typography>
        <Typography>Best Of Luck</Typography>
      </Paper>
      {/* <Paper
        sx={{
          bgcolor: screenSwitches > 0 ? "red" : "green",
          color: "white",
          textAlign: "center",
          p: 2,
        }}
      > */}
        <br/>
        {/* <Typography>
          You have cheated from side screen or tab: {screenSwitches} times
        </Typography>
        {screenSwitches && <FontAwesomeIcon icon={faWarning} />} */}
      {/* </Paper> */}
    </Container>
  );
}

export default Result;
