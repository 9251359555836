import Webcam from "react-webcam";
import React, { useRef, useCallback, useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  Fade,
  Backdrop,
  Alert,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEye, faSpinner } from "@fortawesome/free-solid-svg-icons";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { settranscript } from "../../store/actions/test";

import testService from "../../store/services/test.service";
import { useNavigate } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";

import Resizer from "react-image-file-resizer";

const WebcamCapture = () => {
  const webcamRef = useRef(null);
  const [capturedImages, setCapturedImages] = useState([]);
  const [pictureCount, setPictureCount] = useState(0);
  const [processing, setProcessing] = useState(true);
  const [errorAlert, setAlert] = useState({ show: false, message: "" });
  const [loading, setLoading] = useState(true);
  const [nextButtonDisabled, setNextButtonDisabled] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const userData = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const testId = localStorage.getItem("tid");

  const requestFullScreen = () => {
    const elem = document.documentElement; // Apply to the entire document

    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      // Firefox
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      // Chrome, Safari, and Opera
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      // IE/Edge
      elem.msRequestFullscreen();
    }
  };

  // const testid = useSelector(state => state.testReducer.testid);
  //  console.log(testid);
  const { transcript, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition({ continuous: true });

  useEffect(() => {
    checkWebcamAvailability();
    SpeechRecognition.startListening({
      continuous: true,
    });
  }, []);
  // Function to check webcam availability
  const checkWebcamAvailability = async () => {
    try {
      // First, try to get the video stream
      const videoStream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      videoStream.getTracks().forEach((track) => track.stop());
    } catch (videoError) {
      // console.log(videoError);
        setAlert({ show: true, message: "Webcam is not available." });
        setLoading(false);
        return;
    }

    try {
      // Then, try to get the audio stream
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: true,
      });
      audioStream.getTracks().forEach((track) => track.stop());
      setLoading(false);
    } catch (audioError) {
      // console.log(audioError);
        setAlert({ show: true, message: "Audio is not available." });
        setLoading(false);
    }
  };
  const testStarted = true;

  const navigateOnClick = async () => {
    try {
      await testService.testStarted(testStarted, userId, testId);
      navigate("/test");
    } catch (err) {
      console.log("there is an error", err.response);
      if (
        err.response &&
        err.response.status === 400 &&
        err.response.data.message === "Test already started"
      ) {
        const content = `Only one Attempt is allowed by Admin. Please contact Test Admin to restart.`;
        alert(content);
        setProcessing(false);
        return; // Stop execution if the specific error occurs
      }
    }
  };

  
  // Function to capture images
  const capture = useCallback(async () => {
    if (!loading) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        const blob = await fetch(imageSrc).then((res) => res.blob());
        // setCapturedImages((prevImages) => [...prevImages, imageSrc]);
        setCapturedImages((prevImages) => [...prevImages, blob]);
        setPictureCount((prevCount) => prevCount + 1);
      }
    }
  }, [webcamRef, loading]);

  useEffect(() => {
    checkWebcamAvailability();
    SpeechRecognition.startListening({
      continuous: true,
    });
  }, []);

  useEffect(() => {
    if (transcript) {
      // console.log(transcript,"ga");
      dispatch(settranscript(transcript));
    }
  }, [transcript, dispatch]);

  useEffect(() => {
    if (pictureCount < 1) {
      const captureInterval = setInterval(capture, 3000);
      return () => {
        clearInterval(captureInterval);
      };
    } else if (pictureCount === 1) {
      saveImages();
    }
  }, [capture, pictureCount]);
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        "JPEG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  const saveImages = async () => {
    const resizedImages = [];

    // Resize the captured images
    for (const capturedImage of capturedImages) {
      try {
        const resizedImage = await resizeFile(capturedImage);
        resizedImages.push(resizedImage);
      } catch (error) {
        // console.log("Error resizing image:", error);
      }
    }
    const image = resizedImages[0];
    const imageData = image.replace(/^data:image\/jpeg;base64,/, "");
    try {
      const encodings = await testService.getEncoding(imageData, userId);
      setModalMessage("User Information Captured");
      setModalOpen(true);
      setNextButtonDisabled(false);
      setProcessing(false);
      SpeechRecognition.startListening({
        continuous: true,
      }); // Restart speech recognition
    } catch (error) {
      console.error("Error while encoding:", error);
      setModalMessage(
        "Failed to capture images. Please make sure that your face is clear and you are looking towards the camera and center of the screen"
      );
      setNextButtonDisabled(true); // Disable the Next button on failure
      setModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setLoading(false); // Stop loading when modal closes
  };

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  // Render the component
  return (
    <>
      {errorAlert.show && (
        <Alert
          className="fixed top-16 z-40 left-[50%] transform translate-x-[-50%] "
          variant="filled"
          severity="error"
          onClose={() => setAlert({ show: false, message: "" })}
        >
          {errorAlert.message}
        </Alert>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" sx={{ mb: 4 }}>
            Face Registration
          </Typography>
          <Box sx={{ position: "relative" }}>
            <Typography variant="subtitle1" sx={{ mb: 2 }}>
              {processing
                ? "Please wait while your face is being registered."
                : "Face registration successful."}
              <FontAwesomeIcon
                icon={processing ? faSpinner : faCheck}
                spin={processing}
                color={processing ? "black" : "green"}
                size="1x"
              />
            </Typography>
          </Box>
          <Box sx={{ mt: 4 }}>
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin size="3x" />
            ) : (
              <Box>
                <Webcam height={300} width={300} ref={webcamRef} />
              </Box>
            )}
          </Box>
          <Box sx={{ mt: 4 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Captured Images
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
              {/* {capturedImages.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Capture ${index + 1}`}
                height={100}
              />
            ))} */}
              {capturedImages.map((blob, index) => (
                <img
                  key={index}
                  src={URL.createObjectURL(blob)}
                  alt={`Capture ${index + 1}`}
                  height={100}
                />
              ))}
            </Box>
            <Button
              variant="contained"
              color="primary"
              disabled={nextButtonDisabled}
              onClick={() => {
                navigateOnClick();
                requestFullScreen();
              }}
              sx={{ mt: 2 }}
            >
              Next
            </Button>
          </Box>
        </Box>
        {/* Modal */}
        <Modal
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          open={modalOpen}
          onClose={handleCloseModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={modalOpen}>
            <Box
              sx={{
                backgroundColor: "white",
                boxShadow: 24,
                p: 4,
                textAlign: "center",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <Typography id="modal-description">{modalMessage}</Typography>

              {modalMessage ===
                "Failed to capture images. Please make sure that your face is clear and you are looking towards the camera and center of the screen" && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                  onClick={() => {
                    setModalOpen(false);
                    setLoading(true);
                    setPictureCount(0);
                    setCapturedImages();
                    setProcessing(true);
                    setNextButtonDisabled(true);
                    navigate("/dashboard");
                  }}
                >
                  Try Again
                </Button>
              )}
              {!processing && !nextButtonDisabled && (
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                  onClick={() => {
                    navigateOnClick();
                    requestFullScreen();
                  }}
                >
                  Next
                </Button>
              )}
            </Box>
          </Fade>
        </Modal>
      </Box>
    </>
  );
};

export default WebcamCapture;
