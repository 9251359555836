import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Typography,
} from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { useState } from "react";
import { iparhaiServer } from "../../store/services/config/api.config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const QuestionAccordion = ({ no, question, answer, totalMarks,gradelevel }) => {
  const [autoGradeMarks, setAutoGradeMarks] = useState("");
  const [loading, setLoading] = useState(false);
  // console.log(gradelevel,"n")

  const handleAutoGrading = async (q, a, m,g) => {
    try {
      setLoading(true);
      const res = await iparhaiServer.post("/api/question/gradeanswer", {
        question: q,
        answer: a,
        totalMarks:m,
        gradelevel:g
      });
      setLoading(false);
      // console.log(res.data);
      // const marks = res.data.instruction.match(/(\d+\/\d+)/)[0];
      setAutoGradeMarks(res.data.instruction);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  return (
    <>
      <Accordion key={no}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >
          <div className="w-full flex justify-between">
          <Typography>
            <span className="font-semibold">QUESTION No. {++no}:</span>{" "}
            {question}
          </Typography>
          <p className="mx-4 bg-[#b0c8dc] p-1 rounded-lg "><span className="font-medium">Total Marks:</span> <span className="text-[#004e8f] font-semibold">{totalMarks}</span></p>
          </div>
        </AccordionSummary>
        <AccordionDetails className="flex">
          <Typography className="w-[80%]">
            <span className="font-semibold">ANSWER: </span>
            {answer}
          </Typography>
          <div className="flex flex-col items-center bg-slate-100 rounded-lg px-10 py-2  w-[20%]">
            <div className=" my-3">
              <Button
              variant="outlined"
              sx={{
                width:'142px',
                height:'35px',
                p:0
              }}
                onClick={() => handleAutoGrading(question, answer, totalMarks)}
              >
                {loading && (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    // style={{ marginLeft: "8px" }}
                  />
                )}
                {!loading && 'Auto Grading'}
              </Button>
            </div>
            <div className="">
              <code className="font-semibold">Marks:</code>
              <span className="bg-white px-4 py-2 rounded-lg">
                {autoGradeMarks}
              </span>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default QuestionAccordion;
