import React, { useEffect, useState, useMemo, useRef } from "react";
import { Backdrop, Button, CircularProgress, Grid, Paper } from "@mui/material";
import { io } from "socket.io-client";
import Sidebar from "../Navigation/SideBar";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import CallIcon from "@mui/icons-material/Call";
import VideocamRoundedIcon from "@mui/icons-material/VideocamRounded";
import CodeOffIcon from '@mui/icons-material/CodeOff';
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import { useDispatch, useSelector } from "react-redux";
import {
  addAdminMessages,
  removeNewMessages,
  setNewMessages,
} from "../../store/actions/newMessage";

const AdminSupport = () => {
  const messages = useSelector((state) => state.messageReducer.adminMessages);
  const newMessages = useSelector((state) => state.messageReducer.newMessages);
  const users = useSelector((state) => state.messageReducer.connectedUsers);
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);
  const companyId = localStorage.getItem("cid");
  // const socket = useMemo(() => io("http://localhost:8080"), []);
  const [message, setMessage] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [latestMessage, setLatestMessage] = useState(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  };

  useEffect(() => {
    if (users.length === 0) {
      setSelectedUser(null);
    }
  }, [users]);

  const handleSend = () => {
    // try {
    //   socket.emit("send-message", {
    //     message,
    //     recipientId: selectedUser.userId,
    //     userId: companyId,
    //     sender: "admin",
    //   });
    //   dispatch(
    //     addAdminMessages({
    //       message,
    //       recipientId: selectedUser.userId,
    //       userId: companyId,
    //       sender: "admin",
    //     })
    //   );
    //   setLatestMessage([]);
    //   setMessage("");
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const handleSelectUser = (item) => {
    // scrollToBottom();
    // setSelectedUser(item);
    // if (newMessages.includes(item.userId)) {
    //   dispatch(removeNewMessages(item.userId));
    // }
  };

  useEffect(() => {
    if (selectedUser) {
      const userMessages = messages.filter(
        (e) =>
          e.recipientId === selectedUser.userId ||
          e.userId === selectedUser.userId
      );
      if (
        userMessages.length > 0 &&
        userMessages[userMessages.length - 1].sender !== "admin"
      ) {
        setLatestMessage(userMessages[userMessages.length - 1]);
      }
    }
    scrollToBottom();
  }, [messages, selectedUser]);

  useEffect(() => {
    const styleElement = document.createElement("style");
    styleElement.textContent = `
      .custom-scrollbar::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      .custom-scrollbar::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 10px;
      }
     .custom-scrollbar::-webkit-scrollbar-thumb {
                background: #004e8f;
                border-radius: 10px;
            }
     .custom-scrollbar::-webkit-scrollbar-thumb:hover {
                background: #a0c7dc;
            }
    `;
    document.head.appendChild(styleElement);
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={0} sx={{ zIndex: 100 }} sm={3}>
          <Sidebar />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Backdrop
                        sx={{ color: '#fff', zIndex:90 }}
                        open={true}
                    >
                        <h2 className='bg-red-600 py-2 shadow-xl drop-shadow-xl px-10 rounded-xl'>This page is under development!  <span><CodeOffIcon/></span></h2>
                    </Backdrop>
          <div className="flex relative h-screen w-full">
            <div className="w-1/4 bg-white border-r border-gray-300 flex flex-col">
              <header className="p-4 border-b border-gray-300 flex justify-between items-center bg-[#004e8f] text-white">
                <h1 className="text-2xl font-semibold">Support</h1>
              </header>

              <div className="overflow-y-auto flex-1 p-3 mb-9 pb-20 custom-scrollbar">
                {users && users.length > 0 ? (
                  users.map((item, id) => (
                    <div
                      onClick={() => handleSelectUser(item)}
                      key={id}
                      className={`flex ${
                        selectedUser && item.userId === selectedUser.userId
                          ? "bg-[#b0c8dc]"
                          : ""
                      } items-center mb-4 border cursor-pointer hover:bg-gray-100 p-2 rounded-md`}
                    >
                      <div className="w-12 grid place-content-center h-12 bg-gray-300 rounded-full mr-3">
                        <AccountCircleRoundedIcon
                          fontSize="large"
                          color="primary"
                        />
                      </div>
                      <div className="flex-1 relative">
                        {newMessages.includes(item.userId) && (
                          <span className="h-4 w-4 absolute right-1 top-[50%] transform -translate-y-[50%] bg-[#004e8f] rounded-full"></span>
                        )}
                        <h2 className="text-lg font-semibold">
                          {item.userName}
                        </h2>
                        <p className="text-gray-600">{item.email}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="my-2 text-center">no user connected</p>
                )}
              </div>
            </div>

            <div className="flex-1 w-[75%] flex flex-col">
              <header className="bg-[#b0c8dc] flex  justify-between p-4 ">
                <h1 className="text-2xl font-semibold">
                  {selectedUser ? selectedUser.userName : "select user"}
                </h1>
                <div className="flex gap-4">
                  <CallIcon fontSize="large" className="text-[#004e8f]" />
                  <VideocamRoundedIcon
                    fontSize="large"
                    className="text-[#004e8f]"
                  />
                  <MoreVertRoundedIcon
                    fontSize="large"
                    className="text-[#004e8f]"
                  />
                </div>
              </header>

              <div className="flex-1 overflow-y-auto p-4 custom-scrollbar">
                {selectedUser &&
                  messages
                    .filter(
                      (e) =>
                        e.recipientId === selectedUser.userId ||
                        e.userId === selectedUser.userId
                    )
                    .map((item, index) => (
                      <React.Fragment key={index}>
                        {latestMessage &&
                          item === latestMessage &&
                          newMessages.includes(selectedUser.userId) && (
                            <Paper
                              elevation={8}
                              sx={{ bgcolor: "#004e8f", color: "white" }}
                              className="text-center px-2 py-1 w-fit  mx-auto"
                            >
                              new message
                            </Paper>
                          )}
                        <div
                          className={`flex ${
                            item.sender === "admin" ? "justify-end" : ""
                          } my-2`}
                        >
                          <div
                            className={`${
                              item.sender === "admin"
                                ? "bg-[#004e8f] text-white"
                                : "bg-[#b0c8dc]"
                            } max-w-[55%] rounded-xl p-1`}
                            style={{
                              overflowWrap: "break-word",
                              wordWrap: "break-word",
                              hyphens: "auto",
                            }}
                          >
                            <p className="px-2">{item.message}</p>
                          </div>
                        </div>
                      </React.Fragment>
                    ))}
                <div ref={messagesEndRef} />
              </div>

              {selectedUser && (
                <footer className="bg-white border-t border-gray-300 p-4 w-full">
                  <div className="flex gap-2 items-center">
                    <input
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      type="text"
                      placeholder="Type a message..."
                      className="w-full p-2 rounded-md border border-gray-400 focus:outline-none focus:border-blue-500"
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault(); // Prevents the default action of submitting a form
                          handleSend();
                        }
                      }}
                    />
                    <Button
                      variant="contained"
                      disabled={!message}
                      onClick={handleSend}
                      className="bg-[#004e8f] text-white px-4 py-2 rounded-md ml-2"
                    >
                      Send
                    </Button>
                  </div>
                </footer>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminSupport;
