import {
  faClock,
  faQuestionCircle,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Fade,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  Popover,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import ClearIcon from "@mui/icons-material/Clear";

import Test from "../../store/actions/test";
import testService from "../../store/services/test.service";

import jwtDecode from "jwt-decode";
import { useEffect, useState } from "react";
import { iparhaiServer } from "../../store/services/config/api.config";
import TestTable from "../ScheduleTest/TestTable";
import { setCompanyId } from "../../store/actions/newMessage";

function AssessCard({ test, toggle, ShowScore, availableMinutes, getAllTest }) {
  const dispatch = useDispatch();
  const requestFullScreen = () => {
    const elem = document.documentElement; // Apply to the entire document
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      // Firefox
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      // Chrome, Safari, and Opera
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      // IE/Edge
      elem.msRequestFullscreen();
    }
  };

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("token");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customAlert, setCustomAlert] = useState([false, ""]);
  const [ips, setips] = useState(test.IPs ? test.IPs.split("/") : []);
  const [openAnalytics, setOpenAnalytics] = useState(false);
  const [analyticsRow, setAnalyticsRow] = useState([]);
  const [emails, setEmails] = useState([]);
  const [error, setError] = useState([false, ""]);
  const [emailInput, setEmailInput] = useState("");
  const [ip, setIp] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [updateData, setUpdateData] = useState({
    name: test.name,
    description: test.description,
    startdate: "",
    expiredate: "",
    duration: test.duration /60,
    marksToBeDeducted: test.marksToBeDeducted,
    gradelevel: test.gradelevel,
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [openGuidelineModal, setOpenGuidelineModal] = useState(false);
  const [processing, setProcessing] = useState(false);

  const curloc = location.pathname;

  const numberOfQuestions =
    test.questions && Object.keys(test.questions).length;

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleStartClick = async () => {
    localStorage.setItem("tid", test.id);
    localStorage.setItem("cid", test.companyId);
    setProcessing(true);
    if (jwtDecode(token).role !== 2) {
      let res = await testService.pingrequest(test.id);
      if (res.status === 401) {
        navigate("/login");
        return;
      } else if (!res) {
        return;
      } else if (res.status === 404) {
        // console.log(res.data.error)
        alert(res.data.error);
        setProcessing(false);
        return;
      } else {
        setTimeout(() => {
          dispatch(Test.updateQuestions(test.questions));
          dispatch(Test.updateDuration(Math.floor(test.remainingTime)));
          dispatch(Test.updateMode(test.QuestionType));
          dispatch(Test.updateaiproctor(test.enableAiVigilance));
          dispatch(
            Test.updateenableNegativeMarking(test.enableNegativeMarking)
          );
          dispatch(Test.updatemarksToBeDeducted(test.marksToBeDeducted));

          if (test.enableAiVigilance) {
            navigate("/guidelines");
          } else if (
            test.QuestionType === "Text-based" ||
            test.QuestionType === "MCQ-based"
          ) {
            setOpenGuidelineModal(true);
          } else {
            navigate("/test");
          }
          setProcessing(false);
        }, 2000);
      }
    }
  };

  const fetchIp = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Error fetching IP address:", error);
    }
  };

  //  console.log(test.QuestionType,"here")
  const deleteTest = async () => {
    try {
      testService
        .deleteTest(test.id)
        .then((res) => {
          // setError([false, ""]);
          window.location.reload();
        })
        .catch((err) => {
          // setError([true, "Error deleting test"]);
          alert(err);
        });
      // console.log('sdfsfsdfsdobject')
    } catch (error) {
      alert(error);
    }
  };

  const handleAddIP = () => {
    // Regular expression pattern to validate IP address (IPv4) with at least three dots
    const ipPattern = /^(\d{1,3}\.){3}\d{1,3}$/;

    // Trim whitespace from the entered IP and check if it matches the pattern
    if (ip.trim() === "" || !ipPattern.test(ip.trim())) {
      // Invalid IP address, handle error or alert
      setError([
        true,
        "Invalid IP address. Please enter a valid IPv4 address with at least three dots.",
      ]);
      return;
    }

    // IP is valid, add it to the ips state
    setips([...ips, ip.trim()]);
    setIp("");
  };

  const handleTestStarted = async (userId, testId) => {
    try {
      const res = await iparhaiServer.get(
        `/api/test/getSingleInvitation/${userId}&${testId}`
      );
      setError([true, "Successfully Updated"]);
      setTimeout(() => {
        setError([false, ""]);
      }, 2000);
      getUsersOfThisTest();
    } catch (error) {
      // console.log(error.response.data.message)
    }
  };

  const getUsersOfThisTest = async () => {
    try {
      let res = await iparhaiServer.get(
        `/api/test/getInvitationsOfTest/${test.id}`
      );
      setAnalyticsRow(res.data);
      // console.log(res.data)
    } catch (error) {}
  };

  const analyticsColumns = [
    {
      field: "email",
      headerName: "Email",
      width: 180,
      flex: 1,
      align: "left",
    },
    {
      field: "testStarted",
      headerName: "Test Started",
      width: 180,
      flex: 1,
      align: "left",
      renderCell: (params) => (
        <Button
          disabled={params.row.testStarted === false}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleTestStarted(params.row.id, test.id)}
        >
          {params.row.testStarted ? "Restart" : "Not Started"}
        </Button>
      ),
    },
  ];

  const handleRemoveIp = (index) => {
    setips(ips.filter((_, i) => i !== index));
  };

  const handleRemoveEmail = (index) => {
    setEmails(emails.filter((_, i) => i !== index));
  };

  const handleAddInviteLinks = () => {
    const maxEmails = availableMinutes / test.duration; // Calculate the maximum number of emails based on the total time and test duration

    if (validateEmail(emailInput)) {
      if (emails.includes(emailInput)) {
        setError([true, "Email already exists"]);
        return;
      }
      if (emails.length >= Math.floor(maxEmails)) {
        setError([true, `You can only add ${Math.floor(maxEmails)} emails`]);
        return;
      }
      setEmails([...emails, emailInput]);
      setEmailInput("");
    } else {
      setError([true, "Invalid Email"]);
    }
  };

  const validateEmail = (email) => {
    const atSymbolIndex = email.indexOf("@");
    return (
      atSymbolIndex > 0 &&
      atSymbolIndex < email.length - 1 &&
      email.indexOf("@", atSymbolIndex + 1) === -1
    );
  };
  const handleOpeninfo = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleUpdateTest = async () => {
    // Validation for each field
    if (!updateData.name.trim()) {
      setCustomAlert([true, "Please fill the Name field"]);
      return;
    }

    if (!updateData.description.trim()) {
      setCustomAlert([true, "Please fill the Description field"]);
      return;
    }

    if (!updateData.duration) {
      setCustomAlert([true, "Please fill the Duration field"]);
      return;
    }

    if (!updateData.gradelevel) {
      setCustomAlert([true, "Please select the Grade level"]);
      return;
    }

    try {
      setProcessing(true);
      let data = await iparhaiServer.patch(`/api/test/editTest/${test.id}`, {
        ...updateData,
        ips,
        emails,
      });
      setProcessing(false);
      setEmails([]);
      setIsModalOpen(false);
      setError([true, "Successfully Updated"]);
      setTimeout(() => {
        setError([false, ""]);
      }, 2000);
      getAllTest();
    } catch (error) {
      setProcessing(false);
      setError([true, error.response.data.message]);
    }
  };

  const handleOpenAnalytics = async () => {
    getUsersOfThisTest();
    setOpenAnalytics(true);
    try {
    } catch (error) {
      // console.log(error)
    }
  };

  const remainingTime = isNaN(test.remainingTime)
    ? test.duration
    : test.remainingTime;

  return (
    <>
      <Paper
        sx={{
          margin:
            curloc === "/scheduled"
              ? {
                  xs: "0 auto",
                  md: "0",
                }
              : "0",
          borderRadius: "0.5rem",
          position: "relative",
          width: {
            xs: curloc === "/scheduled" ? "90%" : "16rem", //  between 0px and 600px
            sm: curloc === "/scheduled" ? "100%" : "50%", //  between 600px and 960px
            md: curloc === "/scheduled" ? "45%" : "30%", //  between 960px and 1280px
            lg: curloc === "/scheduled" ? "35%" : "30%", // between 1280px and 1920px
          },
          height: "max-content",
          padding: "0.5rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "primary.lighter",
            width: "100%",
            minHeight: "10.5rem",
            borderRadius: "0.25rem",
            padding: "0.5rem 0",
            position: "relative",
          }}
        >
          {jwtDecode(token).role === 2 ? (
            curloc === "/dashboard" ? (
              <Button
                onClick={() => setDeleteModal(true)}
                variant="outlined"
                color="primary"
                sx={{
                  color: "primary",
                  padding: "0",
                  width: "fit-content",
                  alignSelf: "flex-end",
                  mr: "0.25rem",
                  fontSize: "0.8rem",
                  fontWeight: 600,
                }}
              >
                Delete
              </Button>
            ) : (
              <></>
            )
          ) : jwtDecode(token).role === 4 ? (
            curloc === "/dashboard" ? (
              <Button
                onClick={() => setDeleteModal("not allowed")}
                variant="outlined"
                color="primary"
                sx={{
                  color: "primary",
                  padding: "0",
                  width: "fit-content",
                  alignSelf: "flex-end",
                  mr: "0.25rem",
                  fontSize: "0.8rem",
                  fontWeight: 600,
                }}
              >
                Delete
              </Button>
            ) : (
              <></>
            )
          ) : (
            <></>
          )}
          <Box
            sx={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              padding: "0.5rem",
              pt: "4.5rem",
            }}
          >
            <Tooltip title={test.name} placement="top">
              <Typography
                className="w-fit"
                variant="h6"
                sx={{
                  fontSize: "1.125rem",
                  display: "inline",
                }}
                fontWeight={800}
              >
                {test.name.length > 38
                  ? test.name.slice(0, 40) + "..."
                  : test.name}
              </Typography>
            </Tooltip>
            <Box sx={{ display: "flex", gap: "0.5rem" }}>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "0.8rem",
                }}
              >
                <FontAwesomeIcon icon={faQuestionCircle} />{" "}
                {`${numberOfQuestions || test.questionCount} Questions`}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontSize: "0.8rem",
                }}
              >
                <FontAwesomeIcon icon={faClock} />{" "}
                {`${(remainingTime / 60).toFixed(1)} Minute${
                  remainingTime > 1 ? "s" : ""
                }`}
              </Typography>
              {test.code && jwtDecode(token).role === 2 && (
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "0.8rem",
                    fontWeight: 800,
                  }}
                >
                  {test.inviteMethod === 2
                    ? "Invite link sent"
                    : `Code: ${test.code}`}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
        <Typography
          sx={{
            mt: 0.5,
            mb: 2,
            fontSize: {
              xs: "0.7rem", //  between 0px and 600px
              sm: "0.7rem", //  between 600px and 960px
              md: "0.6rem", //  between 960px and 1280px
              lg: "0.7rem", // between 1280px and 1920px
              xl: "0.875rem", //  above 1920px
            },
            lineHeight: "1rem",
            textAlign: "left",
            height: "5rem",
          }}
          variant="subtitle2"
          color={"gray"}
          display={"block"}
        >
          <Typography
            sx={{
              fontSize: {
                xs: "0.7rem", //  between 0px and 600px
                sm: "0.7rem", //  between 600px and 960px
                md: "0.6rem", //  between 960px and 1280px
                lg: "0.7rem", // between 1280px and 1920px
                xl: "0.875rem", //  above 1920px
              },
            }}
            variant="subtitle2"
            color="black"
            fontWeight={800}
            component={"span"}
          >
            Description :{" "}
          </Typography>

          {test.description}
        </Typography>
        {jwtDecode(token).role === 2 && (
          <div className="flex my-2">
            <Button
              sx={{ marginLeft: "auto" }}
              variant="outlined"
              color="info"
              onClick={handleOpenAnalytics}
            >
              Analytics
            </Button>
            <IconButton color="info" onClick={handleOpeninfo}>
              <InfoIcon />
            </IconButton>
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert
                onClose={handleClose}
                severity="info"
                sx={{ width: "100%" }}
              >
                This Analytics is to monitor Enrollments of Number of candidates
                and Restart the test if needed
              </Alert>
            </Snackbar>
          </div>
        )}
        <Button
          id="startTestButton"
          variant="contained"
          size="small"
          sx={{
            borderRadius: "0.5rem",
            fontSize: "1rem",
            minHeight: "36px",
            position: "relative",
          }}
          onClick={async (e) => {
            if (jwtDecode(token).role === 2 || jwtDecode(token).role === 4) {
              if (curloc === "/dashboard") {
                openModal();
              } else {
                toggle();
              }
            } else {
              if (test.IPs) {
                setProcessing(true);
                const ip = await fetchIp();
                let IPs = test.IPs.split("/");

                if (IPs.includes(ip)) {
                  handleStartClick();
                } else {
                  alert("Your IP is Restricted");
                  setProcessing(false);
                  return;
                }
              } else {
                handleStartClick();
              }
            }
          }}
        >
          {processing ? (
            <FontAwesomeIcon
              icon={faSpinner}
              spin
              color="#FFF"
              size="1x"
              style={{
                position: "absolute",
                left: "50%",
                top: "40%",
                transform: "translate(-50%, -50%)",
              }} // Center the spinner
            />
          ) : // Show appropriate text based on role and curloc
          jwtDecode(token).role === 2 || jwtDecode(token).role === 4 ? (
            curloc === "/dashboard" ? (
              "Edit Assessment"
            ) : ShowScore ? (
              "Hide Report <"
            ) : (
              "View Report >"
            )
          ) : (
            "Start Test"
          )}
        </Button>

        <Dialog open={isModalOpen} onClose={closeModal} fullWidth>
          <DialogContent>
            {customAlert[0] &&
              (setTimeout(() => {
                setCustomAlert([false, ""]);
              }, 2000),
              (
                <Alert
                  sx={{
                    width: "fit-content",
                  }}
                  severity="error"
                >
                  {customAlert[1]}
                </Alert>
              ))}
            <div className="flex gap-4 flex-col">
              <TextField
                value={updateData.name}
                onChange={(e) =>
                  setUpdateData({ ...updateData, name: e.target.value })
                }
                fullWidth
                id="outlined-basic"
                label="Name"
                variant="outlined"
              />
              <TextField
                value={updateData.description}
                onChange={(e) =>
                  setUpdateData({ ...updateData, description: e.target.value })
                }
                fullWidth
                id="outlined-basic"
                label="Description"
                variant="outlined"
              />
              <TextField
                fullWidth
                id="outlined-basic"
                type="number"
                label="Duration"
                variant="outlined"
                value={updateData.duration}
                onChange={(e) => {
                  const value = e.target.value;
                  // Ensure the value does not exceed 999
                  if (
                    value === "" ||
                    (Number(value) <= 999 && Number(value) >= 1)
                  ) {
                    setUpdateData({ ...updateData, duration: value });
                  }
                }}
              />
              {test.marksToBeDeducted && (
                <TextField
                  value={updateData.marksToBeDeducted}
                  onChange={(e) =>
                    setUpdateData({
                      ...updateData,
                      marksToBeDeducted: e.target.value,
                    })
                  }
                  fullWidth
                  id="outlined-basic"
                  label="Marks to be deducted"
                  variant="outlined"
                />
              )}
              <Box display="flex" alignItems="center">
                <TextField
                  // disabled={loading}
                  fullWidth
                  select
                  label="Grade level"
                  value={updateData.gradelevel}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, gradelevel: e.target.value })
                  }
                >
                  <MenuItem value="Primary">Primary</MenuItem>
                  <MenuItem value="Higher">High</MenuItem>
                  <MenuItem value="Intermediate">Intermediate</MenuItem>
                  <MenuItem value="Bachelors">Bachelors</MenuItem>
                  <MenuItem value="Masters">Masters</MenuItem>
                  <MenuItem value="Ph.D">Ph.D</MenuItem>
                </TextField>
                <IconButton
                  size="small"
                  aria-label="info"
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  style={{ marginLeft: "8px" }}
                >
                  <InfoIcon />
                </IconButton>
              </Box>
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <Typography style={{ margin: "12px", maxWidth: "300px" }}>
                  Information about Grade Levels. For taking admission tests,
                  please select one level below.
                </Typography>
              </Popover>
              <div className="flex gap-4">
                <TextField
                  fullWidth
                  label="Start Date and Time"
                  type="datetime-local"
                  value={updateData.startdate}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, startdate: e.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
                <TextField
                  fullWidth
                  label="End Date and Time"
                  type="datetime-local"
                  value={updateData.expiredate}
                  onChange={(e) =>
                    setUpdateData({ ...updateData, expiredate: e.target.value })
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className="mt-2 flex gap-4 items-center">
                <TextField
                  type="email"
                  sx={{ width: 250 }}
                  value={ip}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleAddIP();
                    }
                  }}
                  onChange={(e) => {
                    setIp(e.target.value);
                  }}
                  id="outlined-basic"
                  label="Enter IP address"
                  variant="outlined"
                />
                <Button variant="contained" onClick={handleAddIP}>
                  ADD
                </Button>
              </div>
              {ips.length > 0 && (
                <>
                  <div className="w-full bg-slate-100 flex gap-2 flex-wrap rounded-lg p-2">
                    {ips.map((e, id) => {
                      if (e !== "") {
                        return (
                          <span
                            className="bg-[#b0c8dc] rounded-xl text-sm font-medium px-2"
                            key={id}
                          >
                            {e}{" "}
                            <button onClick={() => handleRemoveIp(id)}>
                              <ClearIcon
                                color="error"
                                className="cursor-pointer"
                              />
                            </button>{" "}
                          </span>
                        );
                      }
                    })}
                  </div>
                </>
              )}

              {test.inviteMethod === 2 && (
                <div className="mt-4 flex gap-4 items-center">
                  <TextField
                    type="email"
                    sx={{ width: 250 }}
                    value={emailInput}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleAddInviteLinks();
                      }
                    }}
                    onChange={(e) => {
                      setEmailInput(e.target.value);
                    }}
                    id="outlined-basic"
                    label="Enter Email"
                    variant="outlined"
                  />
                  <Button variant="contained" onClick={handleAddInviteLinks}>
                    ADD
                  </Button>
                </div>
              )}
              {emails.length > 0 && (
                <>
                  <div className="w-full bg-slate-100 flex gap-2 flex-wrap rounded-lg p-2">
                    {emails.map((e, id) => {
                      return (
                        <span
                          className="bg-[#b0c8dc] rounded-xl text-sm font-medium px-2"
                          key={id}
                        >
                          {e}{" "}
                          <button onClick={() => handleRemoveEmail(id)}>
                            <ClearIcon
                              color="error"
                              className="cursor-pointer"
                            />
                          </button>{" "}
                        </span>
                      );
                    })}
                  </div>
                </>
              )}

              <Button
                sx={{ marginTop: 5 }}
                onClick={handleUpdateTest}
                variant="contained"
                fullWidth
              >
                {processing ? (
                  <FontAwesomeIcon
                    icon={faSpinner}
                    spin
                    color="#FFF"
                    size="1x"
                    style={{
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                ) : (
                  " UPDATE"
                )}
              </Button>
            </div>
            {/* <Createassessment test={test} /> */}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeModal} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        {/* ****************** ANALYTICS *********************** */}
        <Dialog
          open={openAnalytics}
          onClose={() => setOpenAnalytics(false)}
          fullScreen
        >
          <DialogContent>
            <TestTable columns={analyticsColumns} rows={analyticsRow} />
          </DialogContent>
          <DialogActions>
            <div className="w-full flex justify-center">
              <Button
                variant="contained"
                onClick={() => setOpenAnalytics(false)}
                color="primary"
              >
                Close
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </Paper>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openGuidelineModal}
        // onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openGuidelineModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              // width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              // p: 4,
            }}
          >
            <div className="px-10 py-2">
              <Box
                id="yourContainerId"
                sx={{
                  marginBottom: 2,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 800,
                    fontSize: 30,
                    marginBottom: 1,
                    textAlign: "center",
                  }}
                >
                  Remember Candidate, Your Activity Will Be Monitored Throughout
                  the Test.
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: 1.5,
                    marginBottom: 1,
                    textAlign: "center",
                  }}
                >
                  Any Unfair Means Detected You Will Be Disqualified.
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: 1.5,
                    marginBottom: 1,
                    textAlign: "center",
                  }}
                >
                  This includes: 1. Tab Switching 2. Your Device Activity 3.
                  Your Voice Detection
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 800,
                    fontSize: 30,
                    marginBottom: 1,
                    textAlign: "center",
                  }}
                >
                  Test Guidlines
                </Typography>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 500,
                    lineHeight: 1.5,
                    marginBottom: 1,
                    textAlign: "center",
                  }}
                >
                  1. For Logical Reasoning it will be graded by AI so make sure
                  your spellings and context must be correct
                  <br />
                  2. For MCQs based test make sure to select correct option once
                  selected it cannot be back
                </Typography>

                <div className="flex justify-center items-center gap-8">
                  <Button
                    variant="contained"
                    onClick={async () => {
                      let userId = jwtDecode(token).id;
                      try {
                        await testService.testStarted(true, userId, test.id);
                        navigate("/test");
                      } catch (err) {
                        // console.log("there is an error", err);
                        if (
                          err.response &&
                          err.response.status === 400 &&
                          err.response.data.message === "Test already started"
                        ) {
                          const content = `Only one Attempt is allowed by Admin. Please contact Test Admin to restart.`;
                          alert(content);
                          setProcessing(false);
                          return; // Stop execution if the specific error occurs
                        }
                      }
                      requestFullScreen();
                    }}
                    color="primary"
                  >
                    {processing ? (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        spin
                        color="#FFF"
                        size="1x"
                        style={{
                          position: "absolute",
                          left: "50%",
                          top: "50%",
                          transform: "translate(-50%, -50%)",
                        }}
                      />
                    ) : (
                      "Got It"
                    )}
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setOpenGuidelineModal(false)}
                  >
                    Close
                  </Button>
                </div>
              </Box>
              {/* <div className="">
                <Button onClick={() => navigate("/test")} className="">
                  Next
                </Button>
              </div> */}
            </div>
          </Box>
        </Fade>
      </Modal>
      {/* delete confirmation modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={!!deleteModal} // Ensure this evaluates to true or false
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={!!deleteModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            {deleteModal === "not allowed" ? (
              <>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Not Allowed
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  You are not allowed to delete this test.
                </Typography>
                <Button onClick={() => setDeleteModal(false)}>Close</Button>
              </>
            ) : deleteModal === true ? (
              <>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Delete Confirmation
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  Do you want to delete this test?
                </Typography>
                <div className="flex gap-4 justify-center mt-4">
                  <Button variant="outlined" color="error" onClick={deleteTest}>
                    Delete
                  </Button>
                  <Button onClick={() => setDeleteModal(false)}>Cancel</Button>
                </div>
              </>
            ) : null}
          </Box>
        </Fade>
      </Modal>

      {/* delete confirmation modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={deleteModal}
        // onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={deleteModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Delete Confirmation
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Do you want to delete this test?
            </Typography>
            <div className="flex gap-4 justify-center mt-4">
              <Button variant="outlined" color="error" onClick={deleteTest}>
                Delete
              </Button>
              <Button onClick={() => setDeleteModal(false)}>Cancel</Button>
            </div>
          </Box>
        </Fade>
      </Modal>

      <Snackbar
        open={error[0]}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        // autoHideDuration={6000}
        onClose={() => setError([false, ...error])}
      >
        <Alert
          // onClose={handleClose}
          severity={
            error[1] === "Successfully Updated"
              ? "success"
              : error[1] !== ""
              ? "error"
              : ""
          }
          variant="filled"
          sx={{ width: "100%" }}
        >
          {error[1]}
        </Alert>
      </Snackbar>
    </>
  );
}

export default AssessCard;
