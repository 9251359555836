import authService from "./auth.service";
import { iparhaiServer } from "./config/api.config";
const token = localStorage.getItem("token");

const getAllTests = async (userId) => {
  authService.sessionExpiry();
  return iparhaiServer
    .get(`/api/test/${userId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err.response;
    });
};

const submitscore = (testId) => {
  return iparhaiServer
    .post(`/api/question/userscore${testId}`)
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const submittextanswer = async (data) => {
  return iparhaiServer
    .post("/api/question/userscore", data)
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const getAllScores = async (testId) => {
  return iparhaiServer
    .get(`/api/test/allScores/${testId}`)
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const getEncoding = async (imageData, userId) => {
  return iparhaiServer
    .post("/api/company/facecheck", {
      imageData: imageData,
      userId: userId,
    })
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err.response.data);
    });
};

const submitnoise = async (audio_stream, sample_rate) => {
  return iparhaiServer;
  // .post("/api/test/noise_response", { audio_stream, sample_rate: 8000 })
  // .then((res) => {
  //   return res;
  // })
  // .catch((err) => {
  //   // console.error(err, "Error from backend");
  //   throw err;
  // });
};

const proctor = async (imageData) => {
  const userId = localStorage.getItem("userId");
  const testId = localStorage.getItem("tid");

  return iparhaiServer
    .post("/api/company/faceproctor", {
      imageData,
      userId,
      testId,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err.response;
    });
};
const gazeproctor = async (imageData, screen_size, face) => {
  const userId = localStorage.getItem("userId");
  const testId = localStorage.getItem("tid");
  return iparhaiServer
    .post("/api/test/startproctor", {
      imageData,
      screen_size,
      userId,
      face: "true",
      testId,
    })
    .then((res) => {
      // console.log(res,"here")
      return res;
    })
    .catch((err) => {
      throw err.response;
    });
};
const awsobjectdetect = async (imageData) => {
  const userId = localStorage.getItem("userId");
  const testId = localStorage.getItem("tid");
  return iparhaiServer
    .post("/api/company/detect-objects", {
      imageData,
      testId,
      userId,
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err.response;
    });
};

const getUserEncodings = async (userId) => {
  return iparhaiServer
    .get(`/api/test/getEncodingPerUser/${userId}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

const registerTest = async (userId, testcode, companyId) => {
  authService.sessionExpiry();
  return iparhaiServer
    .post(
      "/api/test/invitation",
      {
        testcode: testcode,
        userId: userId,
        companyId: companyId,
      },
      { headers: { "x-access-token": token } }
    )
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
const testStarted = async (testStarted, userId, testId) => {
  return iparhaiServer
    .post(`/api/test/testSarted`, {
      testStarted: testStarted,
      userId: userId,
      testId: testId,
    })
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const getTests = async (userId) => {
  authService.sessionExpiry();
  return iparhaiServer
    .get(`/api/test/invitationPerUser/${userId}`)
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
};

const getInvites = async (testId, companyId) => {
  return iparhaiServer
    .get(`/api/test/invitationPerTest/${testId}&${companyId}`)
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const scheduleTest = async (testId, companyId, expiryDate) => {
  ("Here");
  return iparhaiServer
    .post(`/api/test/scheduleTest`, {
      testId: testId,
      companyId: companyId,
      expiryDate: expiryDate,
    })
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
const Submitscore = async (tab_switch) => {
  const userId = localStorage.getItem("userId");
  const testId = localStorage.getItem("tid");
  return iparhaiServer
    .post(`/api/test/submitScore`, {
      testId,
      userId,
      tab_switch,
    })
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const createTest = async (
  companyId,
  duration,
  name,
  questions,
  description,
  QuestionType,
  startdate,
  expiredate,
  enableAiVigilance,
  emails,
  ips,
  inviteMethod,
  gradelevel,
  enableNegativeMarking,
  marksToBeDeducted
) => {
  ("Here");
  return iparhaiServer
    .post(`/api/test/createTest`, {
      companyId: companyId,
      duration: duration,
      name: name,
      questions: questions,
      description: description,
      QuestionType: QuestionType,
      startdate: startdate,
      expiredate: expiredate,
      enableAiVigilance: enableAiVigilance,
      emails: emails,
      inviteMethod,
      ips,
      gradelevel: gradelevel,
      enableNegativeMarking: enableNegativeMarking,
      marksToBeDeducted: marksToBeDeducted,
    })
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const editTest = async (
  id,
  duration,
  name,
  questions,
  description,
  startdate,
  expiredate
) => {
  ("Here");
  return iparhaiServer
    .put(`/api/test/editTest/${id}`, {
      duration: duration,
      name: name,
      questions: questions,
      description: description,
      startdate: startdate,
      expiredate: expiredate,
    })
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const deleteTest = async (id) => {
  return iparhaiServer
    .delete(`/api/test/deleteTest/${id}`)
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const updateDuration = async (testId) => {
  ("Here");
  return iparhaiServer
    .post(`/api/test/update-duration/:testId`, {
      testId: testId,
    })
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

const pingrequest = async (testId) => {
  ("Here");
  let res = await authService.sessionExpiry();
  if (!res) {
    return false;
  }
  // let token = localStorage.getItem('token')
  return iparhaiServer
    .get(`/api/test/pingresponse/${testId}?token=${token}`)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((err) => {
      return err.response;
    });
};

export default {
  getEncoding,
  proctor,
  registerTest,
  getTests,
  getInvites,
  scheduleTest,
  Submitscore,
  getUserEncodings,
  createTest,
  editTest,
  awsobjectdetect,
  deleteTest,
  getAllScores,
  getAllTests,
  submitnoise,
  updateDuration,
  pingrequest,
  gazeproctor,
  submitscore,
  submittextanswer,
  testStarted,
};
