import {
  faArrowRightFromBracket,
  faCalendar,
  faFileLines,
  faKey,
  faLandmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Box, Divider, Typography, Button } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, Link, useNavigate } from "react-router-dom";

import auth from "../../store/actions/auth";
import user from "../../store/actions/user";
import authService from "../../store/services/auth.service";

function SideBar({ children }) {
  const token = localStorage.getItem("token");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const curloc = location.pathname;

  const userData = useSelector((state) => state.user);
  let first;
  let second;
  let name;
  let email;

  if (userData.name && userData.email) {
    first = userData.name[0];
    if (userData.name.split(" ").length > 1)
      second = userData.name.split(" ")[1][0];

    name = userData.name;
    email = userData.email;
  }

  useEffect(() => {
    authService
      .getUser(token)
      .then((res) => {
        dispatch(user.setUser(res.data.user));
        localStorage.setItem("userId", res.data.user.id);
      })

      .catch((err) => {
        // dispatch(auth.authenticated(false));
      });
  }, [token, dispatch]);

  async function handleLogout() {
    try {
      await localStorage.removeItem("token");

      dispatch(user.setUser({}));

      navigate("/");
    } catch (error) {
    }
  }

  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      sx={{ boxSizing: "border-box" }}
    >
      <Box
        width="25%"
        height="100vh"
        p={2}
        bgcolor="#004E8F"
        sx={{ boxSizing: "border-box" }}
      >
        <Box
          sx={{
            position: "relative",
            left: "50%",
            borderRadius: "50%",
            transform: "translate(-50%, +50%)",
            height: "75px",
            width: "75px",
            bgcolor: "white",
          }}
        >
          <Avatar
            sx={{
              position: "relative",
              transform: "translate(2.5px, 2.5px)",
              height: "70px",
              width: "70px",
              bgcolor: "#D9E4EE",
              color: "#004E8F",
              fontSize: "38px",
            }}
          >
            {first}
            {second}
          </Avatar>
        </Box>

        <Box
          width="100%"
          textAlign="center"
          bgcolor="white"
          borderRadius={2}
          mb={5}
          pb={2}
          pt={5}
        >
          <Typography fontWeight={800}>{name}</Typography>
          <Typography variant="body2" fontWeight={100}>
            {email}
          </Typography>
        </Box>
        <Box
          width="100%"
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
        >
          <Link to="/user">
            <Button
              variant="contained"
              color={curloc === "/scheduled" ? "secondary" : "primary"}
              sx={{
                justifyContent: "flex-start",
                boxShadow: "none",
                width: "100%",
              }}
            >
              <FontAwesomeIcon
                icon={faCalendar}
                style={{ marginRight: "10px" }}
              />
              Scheduled Assessments
            </Button>
          </Link>

          <Link to="/password">
            <Button
              variant="contained"
              color={curloc === "/password" ? "secondary" : "primary"}
              sx={{
                justifyContent: "flex-start",
                boxShadow: "none",
                width: "100%",
              }}
            >
              <FontAwesomeIcon icon={faKey} style={{ marginRight: "10px" }} />
              Password
            </Button>
          </Link>

          <Divider color="#FFFFFF" />

          <Button
            variant="contained"
            color="primary"
            sx={{
              justifyContent: "flex-start",
              boxShadow: "none",
              width: "100%",
            }}
            onClick={handleLogout}
          >
            <FontAwesomeIcon
              icon={faArrowRightFromBracket}
              style={{ marginRight: "10px" }}
            />
            Logout
          </Button>
        </Box>
      </Box>
      <Box width="75%" minHeight="100vh">
        {children}
      </Box>
    </Box>
  );
}

export default SideBar;
