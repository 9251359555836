import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import TestSideBar from "../components/Test/TestSideBar";
import Testt from "../store/actions/test";
import QuestionCard from "../components/Test/QuestionCard";
import testService from "../store/services/test.service";
import { decrypt } from "../components/encryptfunction/decrypt";
import { Circles } from "react-loader-spinner";
import ChatSupport from "../components/Chat Support/ChatSupport";

// Custom hook for speech-to-text
const useSpeechToText = () => {
  useEffect(() => {
    const recognition = new (window.SpeechRecognition ||
      window.webkitSpeechRecognition)();
    recognition.continuous = true; // Continuous listening
    recognition.onresult = (event) => {
      const lastResult = event.results[event.results.length - 1];
      const transcriptText = lastResult[0].transcript;
    };
    recognition.start(); // Start listening automatically
    return () => {
      recognition.stop();
    };
  }, []); // Run only when component mounts
};

function Test() {
  const userId = localStorage.getItem("userId");
  const testId = localStorage.getItem("tid");
  const [loading, setLoading] = useState(false);
  const [allTest, setAllTest] = useState([]);
  const dispatch = useDispatch();
  const questionsObj = useSelector((state) => state.tests.questions);

  useSpeechToText();

  useEffect(() => {
    if (questionsObj.length === 0) {
      setLoading(true);
      testService
        .getTests(userId)
        .then((res) => {
          const { iv, encryptedData } = res.tests;
          const decryptedText = decrypt(encryptedData, iv);
          const parsedData = JSON.parse(decryptedText);
          setAllTest(parsedData);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false); // Stop loading in case of error
        });
    }
  }, [userId, questionsObj.length]);

  useEffect(() => {
    if (questionsObj.length > 0 || allTest.length > 0) {
      setLoading(false); // Stop loading when questions update
    }
  }, [questionsObj, allTest]);

  const filterTest = allTest.filter((e) => e.id === testId);
  if (filterTest.length > 0) {
    let remainingTime = filterTest[0].remainingTime;
    if (remainingTime < 0) {
      remainingTime = 0;
    }
    dispatch(Testt.updateDuration(Math.floor(remainingTime)));
    dispatch(Testt.updateMode(filterTest[0].QuestionType));
    dispatch(Testt.updateaiproctor(filterTest[0].enableAiVigilance));
    dispatch(
      Testt.updateenableNegativeMarking(filterTest[0].enableNegativeMarking)
    );
  }

  const questions =
    Object.values(questionsObj).length === 0 && filterTest.length > 0
      ? filterTest[0].questions
      : Object.values(questionsObj);
  // useSpeechToText(); // Automatically start speech-to-text on component mount

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const confirmationMessage = "Are you sure you want to leave this page?";
      event.preventDefault();
      event.returnValue = confirmationMessage; // For modern browsers
      return confirmationMessage; // For older browsers
    };

    const handlePopState = (event) => {
      const confirmationMessage = "Are you sure you want to leave this page as it will end your test?";
      const confirmLeave = window.confirm(confirmationMessage);
      if (!confirmLeave) {
        window.history.pushState(null, "", window.location.href);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);

    // Push initial state to enable handling popstate for back button
    window.history.pushState(null, "", window.location.href);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  // const questions = Object.values(questionsObj);

  return (
    <Box>
      <TestSideBar>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: 4,
          }}
        >
          {loading ? (
            <Circles
              height="80"
              width="80"
              color="#004e8f"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          ) : questions.length > 0 ? (
            <QuestionCard questions={questions} />
          ) : (
            <p>No questions available</p>
          )}
        </Box>
        {/* <ChatSupport key={1}/> */}
      </TestSideBar>
    </Box>
  );
}

export default Test;
