import React, { useEffect, useState } from "react";
import {
  Button,
  Typography,
  Divider,
  TextField,
  InputAdornment,
  Modal,
  Box,
  MenuItem,
} from "@mui/material";
import imageCompression from "browser-image-compression";
import { handleImageFile } from "../../../store/services/s3.service";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export const MCQtest = ({
  questionData,
  excelData,
  handleFileChange,
  handlePrevious,
  handleNext,
  setQuestionData,
  setExcelData,
  setError,
  downloadFile,
}) => {
  const [singleQuestion, setSingleQuestion] = useState({
    option1: "",
    option2: "",
    option3: "",
    option4: "",
    optionImage1: "",
    optionImage2: "",
    optionImage3: "",
    optionImage4: "",
    questionImage: "",
    marks: "",
    answer: "",
    question: "",
  });

  const [open, setOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [imageLoading, setImageLoading] = useState({
    questionImage: false,
    optionImage1: false,
    optionImage2: false,
    optionImage3: false,
    optionImage4: false,
  });

  useEffect(() => {}, [singleQuestion]);

  const handleOpen = (url) => {
    setPreviewImage(url);
    setOpen(true);
  };

  const handleImageUpload = async (event, key, index) => {
    let optionName = event.target.name;
    optionName = optionName.slice(0, -1);

    if (singleQuestion[event.target.name] === "") {
      singleQuestion[event.target.name] = optionName +" "+ (index + 1);
    }

    console.log(event.target.name);
    const file = event.target.files[0];
    if (file) {
      try {
        setImageLoading((prev) => ({ ...prev, [key]: true }));
        const options = {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);

        const reader = new FileReader();
        reader.readAsDataURL(compressedFile);
        reader.onloadend = () => {
          setSingleQuestion((prev) => ({
            ...prev,
            [key]: reader.result,
          }));
          setImageLoading((prev) => ({ ...prev, [key]: false }));
        };
      } catch (error) {
        console.error("Error while compressing the image:", error);
        setImageLoading((prev) => ({ ...prev, [key]: false }));
      }
    } else {
      setSingleQuestion((prev) => ({ ...prev, [key]: "" }));
    }
  };

  const handleDelete = (index) => {
    const newExcelData = excelData.filter((_, i) => i !== index);
    const newTextQuestionData = questionData.filter((_, i) => i !== index - 1);

    setExcelData(newExcelData);
    setQuestionData(newTextQuestionData);
  };

  const handleClose = () => setOpen(false);

  return (
    <div>
      <Typography variant="h6">Upload Assessment</Typography>
      <Button onClick={() => downloadFile("questions.xlsx")}>
        Download Excel
      </Button>
      <label htmlFor="fileInput">
        <Button
          variant="contained"
          component="span"
          color={questionData.length > 0 ? "error" : "primary"}
        >
          {questionData.length > 0 ? "Change File" : "Upload Excel File"}
        </Button>
      </label>
      <input
        accept=".xlsx, .xls"
        style={{ display: "none" }}
        id="fileInput"
        type="file"
        onClick={(event) => (event.target.value = null)}
        onChange={handleFileChange}
      />

      <div className="mt-4">
        <Divider>OR</Divider>
        <img src="" width={10} />
      </div>

      <div className="space-y-2">
        <TextField
          value={singleQuestion.question}
          onChange={(e) =>
            setSingleQuestion({ ...singleQuestion, question: e.target.value })
          }
          fullWidth
          id="outlined-basic"
          label="Enter Question"
          variant="outlined"
          disabled={imageLoading.questionImage}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <label htmlFor="questionImageInput">
                  {imageLoading.questionImage ? (
                    "Uploading..."
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        component="span"
                        color={
                          singleQuestion.questionImage ? "error" : "primary"
                        }
                        disabled={imageLoading.questionImage}
                      >
                        {singleQuestion.questionImage
                          ? "Change Image"
                          : "Upload Image"}
                      </Button>
                    </>
                  )}
                </label>
                <input
                  disabled={imageLoading.questionImage}
                  accept=".jpeg, .png, .jpg"
                  style={{ display: "none" }}
                  id="questionImageInput"
                  type="file"
                  onClick={(event) => (event.target.value = null)}
                  onChange={(e) => {
                    handleImageUpload(e, "questionImage");
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
        {["option1", "option2", "option3", "option4"].map((option, index) => (
          <TextField
            key={option}
            value={singleQuestion[option]}
            onChange={(e) =>
              setSingleQuestion({ ...singleQuestion, [option]: e.target.value })
            }
            fullWidth
            id={`outlined-basic-${option}`}
            label={`Option ${index + 1}`}
            variant="outlined"
            disabled={imageLoading[`optionImage${index + 1}`]}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <label htmlFor={`optionImageInput${index + 1}`}>
                    {imageLoading[`optionImage${index + 1}`] ? (
                      "Uploading..."
                    ) : (
                      <>
                        <Button
                          variant="contained"
                          component="span"
                          color={
                            singleQuestion[`optionImage${index + 1}`]
                              ? "error"
                              : "primary"
                          }
                          disabled={imageLoading[`optionImage${index + 1}`]}
                        >
                          {singleQuestion[`optionImage${index + 1}`]
                            ? "Change Image"
                            : "Upload Image"}
                        </Button>
                      </>
                    )}
                  </label>
                  <input
                    disabled={imageLoading[`optionImage${index + 1}`]}
                    accept=".jpeg, .png, .jpg"
                    style={{ display: "none" }}
                    id={`optionImageInput${index + 1}`}
                    type="file"
                    name={option}
                    onClick={(event) => (event.target.value = null)}
                    onChange={(e) => {
                      handleImageUpload(e, `optionImage${index + 1}`, index);
                    }}
                  />
                </InputAdornment>
              ),
            }}
          />
        ))}
        <TextField
          type="number"
          value={singleQuestion.marks}
          onChange={(e) =>
            setSingleQuestion({ ...singleQuestion, marks: e.target.value })
          }
          fullWidth
          id="outlined-basic-marks"
          label="Marks"
          variant="outlined"
          disabled={false}
        />
        <TextField
          fullWidth
          select
          label="Answer"
          value={singleQuestion.answer}
          onChange={(e) =>
            setSingleQuestion({ ...singleQuestion, answer: e.target.value })
          }
        >
          {["option1", "option2", "option3", "option4"].map((option, index) => (
            <MenuItem key={option} value={singleQuestion[option]}>
              Option {index + 1}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className="my-2">
        <Button
          onClick={async (e) => {
            if (
              singleQuestion.question === "" ||
              singleQuestion.option1 === "" ||
              singleQuestion.option2 === "" ||
              singleQuestion.option3 === "" ||
              singleQuestion.option4 === "" ||
              singleQuestion.marks === "" ||
              singleQuestion.answer === ""
            ) {
              setError([true, "field can't be empty"]);
              return;
            }
            setLoading(true);
            const questionWithImages = {
              ...singleQuestion,
            };
            setLoading(false);
            handleFileChange(e, questionWithImages);
            setSingleQuestion({
              option1: "",
              option2: "",
              option3: "",
              option4: "",
              optionImage1: "",
              optionImage2: "",
              optionImage3: "",
              optionImage4: "",
              answer: "",
              marks: "",
              questionImage: "",
              question: "",
            });
          }}
          variant="contained"
          fullWidth
          disabled={false}
        >
          {false ? "ADDING..." : "ADD"}
        </Button>
      </div>

      {excelData.length > 1 && (
        <div className="mt-4">
          <Divider>PREVIEW</Divider>
        </div>
      )}

      {excelData.length > 1 && (
        <div className="overflow-x-scroll h-96">
          <table
            style={{
              fontFamily: "Arial, Helvetica, sans-serif",
              borderCollapse: "collapse",
              width: "100%",
              marginTop: "10px",
              overflowX: "scroll",
            }}
          >
            <thead>
              {excelData.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  style={{
                    backgroundColor: rowIndex === 0 ? "#004E8F" : "",
                    color: rowIndex === 0 ? "white" : "",
                  }}
                >
                  {row.map((cell, cellIndex) =>
                    rowIndex === 0 ? (
                      <th
                        key={cellIndex}
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          paddingTop: "12px",
                          paddingBottom: "12px",
                          textAlign: "left",
                        }}
                      >
                        {cell}
                      </th>
                    ) : null
                  )}
                </tr>
              ))}
            </thead>
            <tbody>
              {excelData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, cellIndex) =>
                    rowIndex !== 0 ? (
                      <td
                        key={cellIndex}
                        style={{ border: "1px solid #ddd", padding: "8px" }}
                      >
                        {cellIndex === 1 ||
                        cellIndex === 4 ||
                        cellIndex === 3 ||
                        (cellIndex === 2 && cell) ? (
                          <div>
                            <p>{cell.text || cell}</p>
                            {cell.image && (
                              <Button
                                variant="outlined"
                                onClick={() => handleOpen(cell.image)}
                              >
                                Preview
                              </Button>
                            )}
                          </div>
                        ) : cellIndex === 5 && cell ? (
                          <Button
                            variant="outlined"
                            onClick={() => handleOpen(cell)}
                          >
                            Preview
                          </Button>
                        ) : cellIndex === 9 ? (
                          <Button
                            color="error"
                            variant="outlined"
                            onClick={() => handleDelete(rowIndex)}
                          >
                            Delete
                          </Button>
                        ) : (
                          cell
                        )}
                      </td>
                    ) : null
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <div className="w-[800px] flex justify-center">
            <img
              src={previewImage}
              alt="Preview"
              style={{ maxHeight: "600px" }}
            />
          </div>
          <Button
            onClick={handleClose}
            variant="contained"
            style={{ marginTop: "10px" }}
          >
            Close
          </Button>
        </Box>
      </Modal>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "16px",
        }}
      >
        <Button onClick={handlePrevious}>Previous</Button>
        <Button variant="contained" onClick={handleNext}>
          Next
        </Button>
      </div>
    </div>
  );
};
