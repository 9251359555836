// reducers/messageReducer.js

const initialState = {
  adminMessages: [],
  userMessages: [],
  connectedUsers: [],
  companyId: "",
  showAlert: false,
  newMessages: [],
};

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_ADMIN_MESSAGES":
      return {
        ...state,
        adminMessages: [...state.adminMessages, action.payload],
      };
    case "ADD_USER_MESSAGES":
      return {
        ...state,
        userMessages: [...state.userMessages, action.payload],
      };
    case "CONNECTED_USER":
      return {
        ...state,
        connectedUsers: action.payload,
      };
    case "NEW_MESSAGES":
      return {
        ...state,
        newMessages: [...state.newMessages, action.payload],
      };
    case "REMOVE_NEW_MESSAGES":
      return {
        ...state,
        newMessages: state.newMessages.filter((e)=> e !== action.payload),
      };
    case "SHOW_ALERT":
      return {
        ...state,
        showAlert: action.payload,
      };
    case "COMPANY_ID":
      console.log(action.payload);
      return { ...state, companyId: action.payload };
    default:
      return state;
  }
};

export default messageReducer;
