import React, { useState } from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Button,
  Modal,
  Box,
} from "@mui/material";
import imageCompression from "browser-image-compression";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AnswerInput = ({
  Mode,
  textAnswer,
  setTextAnswer,
  selectedOption,
  setSelectedOption,
  questions,
  questionNumber,
  handleAnswer,
}) => {
  const [open, setOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState("");

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        setImageLoading(true);
        const options = {
          maxSizeMB: 0.5,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };
        const compressedFile = await imageCompression(file, options);
        setImageFile(compressedFile);

        // Convert compressed file to base64 string
        const reader = new FileReader();
        reader.readAsDataURL(compressedFile);
        reader.onloadend = () => {
          setImageUrl(reader.result);
          setImageLoading(false);
        };
      } catch (error) {
        console.error("Error while compressing the image:", error);
        setImageLoading(false);
      }
    } else {
      // Clear the imageUrl if no file is selected
      setImageUrl("");
      setImageFile(null);
    }
  };

  const handleTextFieldChange = (e) => {
    const value = e.target.value;
    const lines = value.split("\n");
    const firstLine = lines[0];

    // Automatically insert newline character when first line reaches 5 characters
    if (firstLine.length > 60) {
      lines[0] = firstLine.slice(0, 60);
      lines.splice(1, 0, firstLine.slice(60));
    }

    setTextAnswer(lines.join("\n"));
  };

  const handleOpen = (url) => {
    setPreviewImage(url);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      {Mode === "Text-based" ? (
        // <div className="w-full relative mt-10">
          <TextField
            label="Answer"
            variant="outlined"
            multiline
            autoCorrect={false}
            rows={questions[questionNumber].type === "Short Answer" ? 4 : 14}
            fullWidth
            value={textAnswer}
            onChange={handleTextFieldChange}
          />
        // </div>
      ) : (
        <FormControl className="self-start w-full">
          <RadioGroup
            className="mt-10"
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={selectedOption}
            onChange={handleAnswer}
          >
            <div className="flex gap-10 items-center justify-between w-full">
              <FormControlLabel
                value={questions[questionNumber].option1}
                control={<Radio />}
                label={questions[questionNumber].option1}
              />

              {questions[questionNumber].optionImage1 && (
                <Button
                  onClick={() =>
                    handleOpen(questions[questionNumber].optionImage1)
                  }
                  variant="outlined"
                >
                  IMAGE
                </Button>
              )}
            </div>
            <div className="flex gap-10 rounded-xl items-center justify-between w-full">
              <FormControlLabel
                value={questions[questionNumber].option2}
                control={<Radio />}
                label={questions[questionNumber].option2}
              />
              {questions[questionNumber].optionImage2 && (
                <Button
                  onClick={() =>
                    handleOpen(questions[questionNumber].optionImage2)
                  }
                  variant="outlined"
                >
                  IMAGE
                </Button>
              )}
            </div>
            <div className="flex gap-10 items-center justify-between w-full">
              <FormControlLabel
                value={questions[questionNumber].option3}
                control={<Radio />}
                label={questions[questionNumber].option3}
              />
              {questions[questionNumber].optionImage3 && (
                <Button
                  onClick={() =>
                    handleOpen(questions[questionNumber].optionImage3)
                  }
                  variant="outlined"
                >
                  IMAGE
                </Button>
              )}
            </div>
            <div className="flex gap-10 items-center justify-between w-full">
              <FormControlLabel
                value={questions[questionNumber].option4}
                control={<Radio />}
                label={questions[questionNumber].option4}
              />
              {questions[questionNumber].optionImage4 && (
                <Button
                  onClick={() =>
                    handleOpen(questions[questionNumber].optionImage4)
                  }
                  variant="outlined"
                >
                  IMAGE
                </Button>
              )}
            </div>
          </RadioGroup>
        </FormControl>
      )}
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <div className="w-[800px] flex justify-center">
            <img
              src={previewImage}
              alt="Preview"
              style={{ maxHeight: "600px" }}
            />
          </div>
          <Button
            onClick={handleClose}
            variant="contained"
            style={{ marginTop: "10px" }}
          >
            Close
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default AnswerInput;
