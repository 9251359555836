const setTests = (payload) => {
  return {
    type: "update",
    payload,
  };
};

const updateQuestions = (payload) => {
  return {
    type: "updateQuestions",
    payload,
  };
};
const updateAnswers = (payload) => {
  return {
    type: "updateAnswers",
    payload,
  };
};

const updatePercentage = (payload) => {
  return {
    type: "updatePercentage",
    payload,
  };
};
const updateDuration = (payload) => {
  return {
    type: "updateDuration",
    payload,
  };
};
const updateTestId  = (payload) => {
  return {
    type: "updateTestId",
    payload,
  };
}
export const settranscript = (payload) => {
  return {
    type: "updatetranscripttext",
    payload,
  };
}
export const updateMode = (payload) => {
  return {
    type: "updatemode",
    payload,
  };
}
export const updateaiproctor = (payload) => {
  return {
    type: "updateaiproctor",
    payload,
  };
}
export const updateenableNegativeMarking = (payload) => {
  return {
    type: "updatenegmarks",
    payload,
  };
}
export const updatemarksToBeDeducted = (payload) => {
  return {
    type: "updatedetectmark",
    payload,
  };
}

export default { updateTestId,setTests, updateQuestions, updateAnswers, updatePercentage,updateMode,updateDuration,updateTestId,updateaiproctor,updateenableNegativeMarking,updatemarksToBeDeducted};
