import React, { useState, useEffect, useMemo } from "react";
import { createTheme, Paper, ThemeProvider } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Navigation from "./components/Navigation/Navigation";
import "./App.css";
import jwtDecode from "jwt-decode";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import {
  addAdminMessages,
  connectedUsers,
  setCompanyId,
  setNewMessages,
  setShowAlert,
} from "./store/actions/newMessage";

// Define the MUI theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#004E8F",
      lighter: "#B0C8DC",
    },
    secondary: {
      gold: "#FFB61D",
      main: "#FFFFFF",
    },
    light: {
      main: "#F5F5F5",
    },
  },
  typography: {
    fontFamily: "Poppins, sans-serif",
  },
});

// Function to initialize socket connection
// const initializeSocket = (companyId, dispatch) => {
//   const socket = io("http://localhost:8080");

//   socket.on("connect", () => {
//     socket.emit("user-connected", {
//       socketId: socket.id,
//       userId: companyId,
//       sender: "admin",
//     });

//     socket.on("connectedUsers", (data) => {
//       console.log("app connected user", data);
//       const myUsers = Object.values(data).filter(
//         (e) => e.companyId === companyId
//       );
//       console.log("my users: ", myUsers);
//       dispatch(connectedUsers(myUsers));
//     });
//   });

//   return socket;
// };

function App() {
  // const dispatch = useDispatch();
  // const storedCompanyId = useMemo(() => localStorage.getItem("cid"), []);
  // const companyId =
  //   useSelector((state) => state.messageReducer?.companyId) || storedCompanyId;
  // const [userRole, setUserRole] = useState(null);
  // const [aalert, setAalert] = useState(false);

  // // Create a memoized socket instance
  // const socket = useMemo(
  //   () => (companyId ? initializeSocket(companyId, dispatch) : null),
  //   [companyId, dispatch]
  // );

  // useEffect(() => {
  //   console.log('message recieving')
  //   if (companyId) {
  //     localStorage.setItem("cid", companyId);
  //   }

  //   const token = localStorage.getItem("token");

  //   const handleMessageReceive = (data) => {
  //     console.log(data);
  //     let a = window.location.hash;
  //     if (a !== "#/support") {
  //       dispatch(setShowAlert(true));
  //       setAalert(true);
  //     }
  //     dispatch(addAdminMessages(data));
  //     dispatch(setNewMessages(data.userId));
  //   };

  //   if (token) {
  //     try {
  //       const decodedToken = jwtDecode(token);
  //       if (decodedToken.role === 2 && companyId) {
  //         socket.on("receive-message", handleMessageReceive);
  //       }
  //       setUserRole(decodedToken.role);
  //     } catch (error) {
  //       console.error("Error decoding token:", error);
  //       setUserRole(null);
  //     }
  //   } else {
  //     setUserRole(null);
  //   }

  //   return () => {
  //     if (socket) {
  //       socket.off("receive-message", handleMessageReceive);
  //       socket.disconnect();
  //     }
  //   };
  // }, [socket, companyId, dispatch]);

  // const handleHideAlert = () => {
  //   setAalert(false);
  // };

  return (
    <ThemeProvider theme={theme}>
      {/* {aalert && userRole === 2 && (
        <Paper
          sx={{ bgcolor: "#004E8F", color: "white" }}
          elevation={8}
          className="absolute text-center z-[101] px-2 py-2 left-[50%] transform mt-10 -translate-x-[50%] w-60"
        >
          New Message!
          <CloseRoundedIcon
            className="cursor-pointer ml-auto"
            onClick={handleHideAlert}
            sx={{ color: "white", marginLeft: 1 }}
          >
            Cancel
          </CloseRoundedIcon>
        </Paper>
      )} */}
      <Navigation />
    </ThemeProvider>
  );
}

export default App;
