import React, { useState } from 'react';
import { TextField, FormControl, Select, MenuItem, InputLabel, Typography, Checkbox, Button, FormControlLabel, Alert, Box } from '@mui/material';
import { IconButton, Popover } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const Creationform = ({
  assessmentName = "",
  setAssessmentName,
  assessmentDescription = "",
  setAssessmentDescription,
  questionType = "",
  gradelevel="",
  setgradelevel,
  setQuestionType,
  assessmentDuration = "",
  setAssessmentDuration,
  startDate = "",
  setStartDate,
  endDate = "",
  setEndDate,
  enableAiVigilance = false,
  setEnableAiVigilance,
  handleNext,
  enableNegativeMarking=false,
  setEnableNegativeMarking,
  marksToBeDeducted,
  setMarksToBeDeducted,
  error = [false, ""],
  setError,
  fieldStyle = { marginBottom: "16px" }
}) => {
  const [anchorEl, setAnchorEl] = useState(null);


  return (
    <div>
      <br />
      <TextField
        fullWidth
        label={<Typography variant="subtitle1" gutterBottom>Assessment Name</Typography>}
        placeholder="Assessment Name"
        value={assessmentName}
        onChange={(e) => setAssessmentName(e.target.value)}
        style={fieldStyle}
      />
      <TextField
        fullWidth
        label={<Typography variant="subtitle1" gutterBottom>Assessment Description</Typography>}
        placeholder="Assessment Description"
        multiline
        rows={4}
        value={assessmentDescription}
        onChange={(e) => setAssessmentDescription(e.target.value)}
        style={fieldStyle}
      />
      <FormControl fullWidth style={fieldStyle}>
        <InputLabel id="assessment-type-label">Assessment Type</InputLabel>
        <Select
          labelId="assessment-type-label"
          id="assessment-type-select"
          value={questionType}
          onChange={(e) => setQuestionType(e.target.value)}
          displayEmpty
        >
          <MenuItem value="MCQ-based">MCQ-based</MenuItem>
          <MenuItem value="Text-based">Text Based</MenuItem>
        </Select>
      </FormControl>
      <Box display="flex" alignItems="center" style={fieldStyle}>
      <TextField
          // disabled={loading}
          fullWidth
          select
          label="Grade level"
          value={gradelevel}
          onChange={(e) =>
            setgradelevel(e.target.value)
            // setSingleQuestion({ ...singleQuestion, answer: e.target.value })
          }
        >
          <MenuItem value="Primary">Primary</MenuItem>
            <MenuItem value="Higher">High</MenuItem>
            <MenuItem value="Intermediate">Intermediate</MenuItem>
            <MenuItem value="Bachelors">Bachelors</MenuItem>
            <MenuItem value="Masters">Masters</MenuItem>
            <MenuItem value="Ph.D">Ph.D</MenuItem>
        </TextField>
        <IconButton
          size="small"
          aria-label="info"
          onClick={(e) => setAnchorEl(e.currentTarget)}
          style={{ marginLeft: '8px' }}
        >
          <InfoIcon />
        </IconButton>
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Typography style={{ margin: '12px', maxWidth: '300px' }}>
          Information about Grade Levels.
          For taking admission tests, please select one level below.
        </Typography>
      </Popover>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          gap: '16px',
          marginTop: '16px',
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={enableNegativeMarking}
              onChange={(e) => setEnableNegativeMarking(e.target.checked)}
            />
          }
          label="Negative Marking per Question"
        />
      </div>
      {enableNegativeMarking && (
        <TextField
          fullWidth
          label={<Typography variant="marks" gutterBottom>Marks to be Deducted</Typography>}
          placeholder="Enter marks to be deducted"
          type="number"
          inputProps={{ min: "0", step: "0.01" }}
          value={marksToBeDeducted}
          onChange={(e) => {
            const enteredValue = e.target.value;
            if (/^\d*\.?\d*$/.test(enteredValue) || enteredValue === "") {
              setMarksToBeDeducted(enteredValue);
            }
          }}
          style={fieldStyle}
        />
      )}
      <TextField
        fullWidth
        label={<Typography variant="subtitle1" gutterBottom>Assessment Duration</Typography>}
        placeholder="Enter Duration in Minutes"
        type="number"
        inputProps={{ min: "1", max: "999" }}
        value={assessmentDuration}
        onChange={(e) => {
          const enteredValue = e.target.value;
          if (/^\d+$/.test(enteredValue) || enteredValue === "") {
            setAssessmentDuration(enteredValue);
          }
        }}
        style={fieldStyle}
      />
      <TextField
        fullWidth
        label="Start Date and Time"
        type="datetime-local"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
        style={fieldStyle}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        fullWidth
        label="End Date and Time"
        type="datetime-local"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
        style={fieldStyle}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          gap: '16px',
          marginTop: '16px',
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={enableAiVigilance}
              onChange={(e) => setEnableAiVigilance(e.target.checked)}
            />
          }
          label="Enable AI Vigilance"
        />
      </div>
     
      <Button variant="contained" onClick={handleNext} style={{ marginTop: '16px' }}>Next</Button>
    </div>
  );
};

export default Creationform;
